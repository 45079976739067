import { produce } from "immer";
import {
  SET_FILES,
  FILE_UPLOAD_COMPLETED,
  FILES_S3,
  FILES_S3_SIGNED_UPLOADED,
  SET_CLEAR_FILES,
  REMOVE_FILE_S3,
  START_FILE_UPLOAD,
  UPDATE_UPLOAD_PROGRESS,
  FILE_UPLOAD_FAILED,
  MARK_FILE_SAVED,
  RESET_FILE_UPLOAD_STATE,
  UPDATE_UPLOAD_SPEED,
  REMOVE_FILE_UPLOAD,
  SET_INITIAL_UPLOAD_DELAY_MESSAGE,
  SET_FILE_REUPLOAD,
  MULTI_PART_UPLOAD_DATABANK_COMPLETED,
  SAVE_CANCEL_TOKENS,
  ADD_TO_CANCELED_FILES,
  REMOVE_CANCEL_TOKENS,
  REMOVE_FROM_CANCELED_FILES
} from "../actions/Experiments";
import moment from "moment";
import { uploadSpeed } from "../utils/basic";

const initialState = {
  files: [],
  fileUploadCompleted: false,
  filesS3: [],
  fileUploads: {},
  errorFiles: [],
  savedFiles: [],
  showInitialUploadDelayMessage: false,
  canceledFiles: new Set()
};
/**
   * fileUploads state structure:
   * {
   *   [fileName]: {
   *     fileId: string,          // Unique identifier for the file
   *     fileName: string,            // Name of the file
   *     size: number,            // Size of the file in bytes
   *     totalParts: number,      // Total number of parts for multipart upload
   *     completedParts: number,  // Number of parts that have been fully uploaded
   *     parts: number[],         // Array tracking completion percentage of each part
   *     progress: number,        // Overall progress in percentage
   *     errorStatus: boolean,    // Flag indicating if an error occurred
   *     uploadCompleted: boolean, // Flag indicating if the upload has been completed
   *     startedAt: number, 
   *     authError: boolean  // Timestamp when the upload started
   *     reUploadRequired: boolean  
   *     canceledFiles: new Set()  ,
   *     cancelTokens: [], // Initialize empty array for cancel tokens
   *   }
   * }
   */

const fileReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILES: {
      draft.files = [...draft.files, ...action.files];
      return draft;
    }


    case FILES_S3: {
      draft.filesS3.push(payload);
      return draft;
    }

    case REMOVE_FILE_S3: {
      draft.filesS3.forEach((file, index) => {
        if (
          file.fileId == action.file.fileId ||
          file.name == action.file.name
        ) {
          draft.filesS3.splice(index, 1);
        }
      });
      return draft;
    }

    case SET_FILE_REUPLOAD: {
      draft.fileUploads[payload].reUploadRequired = true;
      draft.fileUploads[payload].errorStatus = true;
      break
    }

    // old state may not be needed needs clean up

    case FILES_S3_SIGNED_UPLOADED: {
      var files = payload;
      const fileToUpdate = draft.filesS3.find(
        (f) => f.fileId === files[action.thisFileIndex].fileId
      );
      if (fileToUpdate) {
        fileToUpdate.parts[action.thisPartIndex].uploaded =
          action.progressEvent.loaded;

        const totalUploaded = fileToUpdate.parts.reduce((acc, part) => {
          return acc + part.uploaded;
        }, 0);

        fileToUpdate.percent =
          action.progressEvent.loaded === action.progressEvent.total
            ? 100
            : Math.ceil((totalUploaded / fileToUpdate.size) * 100);

        fileToUpdate.speed = uploadSpeed(
          moment(action.progressEvent.timestamp).unix(),
          fileToUpdate.startedAt,
          fileToUpdate.size,
          totalUploaded
        );
      }

      return draft;
    }

    case SET_CLEAR_FILES: {
      (draft.files = {}),
        (draft.filesS3 = []),
        (draft.fileUploadCompleted = false);
      return draft;
    }

    case SET_INITIAL_UPLOAD_DELAY_MESSAGE: {
      if (!draft.showInitialUploadDelayMessage && payload) {
        draft.showInitialUploadDelayMessage = payload;
      }
      break;
    }

    case START_FILE_UPLOAD: {

      const { fileId, fileName, size, totalParts, createdDt, cancelTokens } = payload;

      // Remove from canceledFiles set if it is being uploaded again
      draft.canceledFiles.delete(fileName);

      // Initialize a new file entry or update an existing one
      if (!draft.fileUploads[fileName]) {
        draft.fileUploads[fileName] = {
          fileId,
          fileName,
          size,
          totalParts,
          createdDt,
          completedParts: 0,
          parts: Array(totalParts).fill(0), // Tracks completion percentage of each part
          progress: 0, // Overall progress in percentage
          errorStatus: false,
          speed: null, // Add speed tracking for each file
          authError: false,
          reUploadRequired: false,
          cancelTokens: cancelTokens, // Initialize empty array for cancel tokens
        };
      }
      draft.errorFiles = []; // Clear errorFiles array when a new file upload is started
      // draft.canceledFiles = new Set(); // Clear canceledFiles set when a new file upload is started
      draft.showInitialUploadDelayMessage = false;
      break;
    }

    // case CANCEL_FILE_UPLOAD: {
    //   const { fileName } = action.payload;
    //   draft.canceledFiles.add(fileName); // Add fileName to canceledFiles
    //   if (draft.fileUploads[fileName]) {
    //     delete draft.fileUploads[fileName]; // Remove from ongoing uploads if needed
    //   }
    //   break;
    // }

    // Save cancel tokens for a file upload
    case SAVE_CANCEL_TOKENS: {
      const { fileName, cancelTokens } = payload;
      if (draft.fileUploads[fileName]) {
        draft.fileUploads[fileName].cancelTokens = cancelTokens;
      }
      break;
    }

    // Remove cancel tokens after canceling an upload
    case REMOVE_CANCEL_TOKENS: {
      const fileName = payload;
      if (draft.fileUploads[fileName]) {
        draft.fileUploads[fileName].cancelTokens = [];
      }
      break;
    }

    // Add file to the canceled files set
    case ADD_TO_CANCELED_FILES: {
      const fileName = payload;
      draft.canceledFiles.add(fileName);
      if (draft.fileUploads[fileName]) {
        delete draft.fileUploads[fileName]; // Remove from ongoing uploads if needed
      }
      break;
    }

    // Remove file from the canceled files set
    case REMOVE_FROM_CANCELED_FILES: {
      const fileName = payload;
      draft.canceledFiles.delete(fileName);
      break;
    }

    // 
    case UPDATE_UPLOAD_PROGRESS: {
      const { fileName, partNumber, partProgress, overallProgress, uploadSpeed } = payload;

      if (draft.fileUploads[fileName]) {
        // Ensure that partNumber is valid and within range
        if (
          typeof partNumber === 'number' &&
          partNumber > 0 &&
          partNumber <= draft.fileUploads[fileName].totalParts &&
          typeof partProgress === 'number'
        ) {
          // Update progress for the specific part (0-based index for array)
          draft.fileUploads[fileName].parts[partNumber - 1] = partProgress;

          // Update completed parts count
          draft.fileUploads[fileName].completedParts =
            draft.fileUploads[fileName].parts.filter((p) => p === 100).length;

          // Update overall progress percentage
          // If all parts are completed, ensure the overall progress is set to 100%
          if (draft.fileUploads[fileName].completedParts === draft.fileUploads[fileName].totalParts) {
            draft.fileUploads[fileName].progress = 100;
          } else if (typeof overallProgress === 'number') {
            // Otherwise, update the overall progress with the received value
            draft.fileUploads[fileName].progress = overallProgress;
          }
          draft.fileUploads[fileName].speed = uploadSpeed;
        }
      }
      break;
    }

    case MULTI_PART_UPLOAD_DATABANK_COMPLETED: {

      if (draft.fileUploads[payload]) {
        draft.fileUploads[payload].uploadCompleted = true; // Mark the specific file upload as completed
      }
      break;
    }


    case FILE_UPLOAD_COMPLETED: {
      draft.fileUploadCompleted = true; // Mark overall file upload as completed in the reducer level
      // if (draft.fileUploads[payload]) {
      //   draft.fileUploads[payload].uploadCompleted = true; // Mark the specific file upload as completed
      // }
      // if (!draft.savedFiles.includes(payload)) {
      //   draft.savedFiles.push(payload); // Add completed file to savedFiles
      // }
      break;
    }

    case UPDATE_UPLOAD_SPEED: {
      console.log({ payload });
      const { fileName, speed } = payload;

      if (draft.fileUploads[fileName]) {
        draft.fileUploads[fileName].speed = speed;
      }

      break;
    }

    case REMOVE_FILE_UPLOAD: {

      const fileName = action?.file;
      //Remove file from fileUploads state
      if (draft.fileUploads[fileName]) {
        delete draft.fileUploads[fileName];
      }


      // Remove from `files` array
      draft.files = draft.files.filter((f) => f !== fileName);

      // Remove from savedFiles and errorfiles arrays
      draft.savedFiles = draft.savedFiles.filter((savedFileName) => savedFileName !== fileName);
      draft.errorFiles = draft.errorFiles.filter((errorFileName) => errorFileName !== fileName);

      break
    }

    case FILE_UPLOAD_FAILED: {
      if (!draft.errorFiles.includes(payload)) {
        draft.errorFiles.push(payload); // Add to errorFiles only if it's not already there
      }
      if (draft.fileUploads[payload]) {
        draft.fileUploads[payload].errorStatus = true; // Mark error status for the specific file

        // Check if the failure is due to authentication error (e.g., a 401 error)
        if (action.errorCode === 401) {
          draft.fileUploads[payload].authError = true; // Mark the authentication error flag
        }
      }
      break;
    }

    case MARK_FILE_SAVED: {
      if (!draft.savedFiles.includes(payload)) {
        draft.savedFiles.push(payload);
      }
      break;
    }

    case RESET_FILE_UPLOAD_STATE: {
      return initialState; // Resetting the state back to initial state
    }

    default: {
      return draft;
    }
  }
});

export default fileReducer;
