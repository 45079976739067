import React, { useState } from 'react';
import 'scss/App.scss';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Box, Menu, MenuItem, Modal, Paper } from '@mui/material';
import User from '../images/icons-header-user.svg';
import UserHover from '../images/icons-header-user-hover.svg';
import { useMsal } from '@azure/msal-react';
import { getUserEmail, getAccessToken } from '../actions/Azure';
import useFetchRecentExperiments from './Hooks/Experiment/useFetchRecentExperiment';
import TitleLogo from './TitleLogo';


function TopNav(props) {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState();
    const userEmail = getUserEmail();
    const open = Boolean(anchorEl);
    const [openModal, setOpenModal] = useState(false);
    const accessToken = getAccessToken();

    const list = useFetchRecentExperiments();
    const allExperiments = list?.data?.data || [];
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleLogOut = (experimentsAll) => {
        const runningExperiments = experimentsAll.some(item => item.status === 'RUNNING');
        if (runningExperiments) {
            setOpenModal(true);
            return;
        }
        return instance.logoutRedirect({
            idTokenHint: accessToken
        });
    }

    let icons;
    if (!props.hideIcon) {
        icons = (
            <Box>
                <a onClick={handleClick} className={'nav-icon'} data-testid={"user"}><img src={User} alt="User" onMouseOver={e => (e.currentTarget.src = UserHover)} onMouseOut={e => (e.currentTarget.src = User)} /></a>
            </Box>
        )
    }

    return (
        <div>
            <AppBar position="sticky" elevation={1} className={'nav'} >
                <Toolbar>
                    <Grid container alignItems={'center'} justifyContent={"flex-start"}>
                        <TitleLogo />
                        <Grid container item xs={4} alignItems={'center'} justifyContent={"flex-end"}>
                            {icons}
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <MenuItem onClick={handleClose}>
                                    <Link to='/profile'>{userEmail}</Link>
                                </MenuItem>
                                <MenuItem onClick={() => handleLogOut(allExperiments)}>Logout</MenuItem>
                            </Menu>
                        </Grid>
                        <Modal open={openModal}>
                            <Paper className="" id="delete-experiment-modal">
                                <Box style={{ padding: '35px' }}>
                                    <p>Are you sure you wish to logout?</p>
                                </Box>
                                <Box id="addtag-footer">
                                    <a className="blue-link medium-text link2" onClick={() => setOpenModal(false)}>CANCEL</a>
                                    <a className="blue-link medium-text" onClick={() => instance.logoutRedirect(
                                        { idTokenHint: getAccessToken() }
                                    )}>LOGOUT</a>
                                </Box>
                            </Paper>
                        </Modal>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default TopNav;