import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./App";
import { initialize, pageview } from "react-ga";
//import { Security } from "@okta/okta-react";
import { IntlProvider } from "react-intl";
import i18nConfig from "../i18n/config";
import { ThemeProvider } from "@material-ui/styles";
import muiTheme from "../theme/muiTheme";
import config from "../app.config";
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from "react-query";
import { PROD_URL } from "./common/Constants/appConstants";
import { useMsal } from "@azure/msal-react";
import { enableMapSet } from 'immer';

const Root = (props) => {

  const [stopRender, setStopRender] = useState(false)
  const { location, store, history } = props;
  const { instance } = useMsal();
  // create a query client that will hoold all your applciations queries

  const queryClient = new QueryClient({
    defaultOptions: {
      // configure cacheTime or staleTime if needed - default is cache is 5 minutes and staleTime is 0
    },
  });

  // Enable Map and Set support in Immer
  enableMapSet();

  useEffect(() => {
    setStopRender(true)
    if (!stopRender) {
      const isProd =
        window.location.hostname === PROD_URL ? true : false;
      initialize(config.ga_account, {
        testMode: !isProd
      });
      pageview(window.location.pathname + window.location.search);
    }

  }, [location, stopRender]);
  return (

    <Provider store={store}>
      <IntlProvider
        locale={i18nConfig.locale}
        defaultLocale={i18nConfig.locale}
        messages={i18nConfig.messages}
      >
        <ConnectedRouter history={history}>
          <ThemeProvider theme={muiTheme}>
            {/* <MsalProvider instance={msalInstance}> */}
            <QueryClientProvider client={queryClient}>
              <App instance={instance} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            {/* </MsalProvider> */}
          </ThemeProvider>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>

  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default Root;
